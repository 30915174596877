import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {},

  heroMedia: {
    "&:hover": {
      "& + figcaption": {
        "& $heroLink": {
          "& $headline": {
            color: theme.palette.common.highlight,
          },
        },
      },
    },
  },

  heroUserLink: {
    "& > div": {
      transition: theme.transitions.create("filter"),
      filter: "grayscale(100%)",
    },

    "&:hover > div": {
      filter: "grayscale(0%)",
    },
  },

  heroLink: {
    "&:hover": {
      "& $headline": {
        color: theme.palette.common.highlight,
      },
    },
  },

  headline: {
    marginBottom: "0.4em",
    color: theme.palette.common.black,
  },

  description: {
    ...theme.typography.subtitle2,
    color: theme.palette.grey[450],
    lineHeight: 1.4,
  },
});

export default makeStyles(styles);
