import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {},

  mobileSlider: {
    [theme.breakpoints.up("md")]: {
      // display: "none",
    },
  },

  desktopGrid: {
    [theme.breakpoints.down("md")]: {
      // display: "none",
    },
  },

  grid: {
    display: "flex",
  },

  slider: {
    margin: `0 ${theme.spacing(-3)}`,

    "& .slick-list": {
      paddingBottom: "1em",
    },

    "& .slick-slide": {
      width: `calc(100vw - ${theme.spacing(5)})`,
      marginRight: theme.spacing(),

      "& > div": {
        height: "100%",
      },
    },
  },

  sliderNormal: {
    "& $sliderArrow": {
      top: 230,
      marginTop: theme.spacing(-3),
    },
  },

  sliderArrow: {},

  cardWrapper: {
    position: "relative",
    height: "100%",
  },

  cardWrapperPartner: {
    "& $card": {
      paddingBottom: "2em",
    },
  },

  card: {
    height: "100%",
    width: "100%",
  },

  reactions: {
    "& p": {
      alignItems: "center",
      display: "flex",
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },

    "& svg": {
      height: 30,
      marginRight: theme.spacing(1.5),
      width: 30,
    },
  },

  partnerHeadline: {
    width: "100%",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    position: "absolute",
    bottom: theme.spacing(),
  },

  partnerLogo: {
    display: "block",
    margin: "0 0 0 auto",
    maxWidth: 50,

    "& img": {
      width: "100%",
      display: "block",
      maxHeight: 30,
    },
  },
});

export default makeStyles(styles);
