import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { Button, Grid, Link, Section, Typography } from "@/ui";

import styles from "./styles";

const BannerStartNow = ({ classes }) => (
  <Section className={classes.root} component="aside" fullWidth>
    <Grid item xs={12}>
      <Typography variant="h3" className={classes.title}>
        Begin your trip report
      </Typography>
      <Typography className={classes.info}>
        Share your trip details including the good and not so good.
      </Typography>
      <Link href="/authoring/overview">
        <Button variant="contained" color="secondary">
          Get Started
        </Button>
      </Link>
    </Grid>
  </Section>
);

BannerStartNow.defaultProps = {
  classes: {},
};

BannerStartNow.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(BannerStartNow);
